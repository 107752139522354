import React, { useState } from 'react'
import Terminal from 'terminal-in-react'

function App() {
    const [showMsg] = useState('')

    return (
        <div style={{height: "100vh"}}>
            <Terminal
                color="green"
                backgroundColor="black"
                barColor="black"
                style={{ fontWeight: 'bold', fontSize: '1em', minHeight: "100vh" }}
                startState={'maximised'}
                allowTabs={false}
                hideTopBar={true}
                commands={{
                    'adminer': () => {
                        window.open('https://root.hopo.online/adminer/', '_blank')
                        return <div>Open adminer</div>
                    },  
                    showmsg: showMsg,
                    popup: () => alert('Terminal in React'),
                }}
                msg=""
                promptSymbol="hoposerver >"
            />
        </div>
    )
}

export default App
